<script setup lang="ts">
const props = defineProps({
  fields: {
    type: Object as PropType<Record<string, any>>,
    default: () => {},
  },
  slice: {
    type: Object as PropType<Record<string, any>>,
    default: () => {},
  },
})

defineSlice({
  name: {
    label: 'Static Gridded Gallery',
    group: 'Glue',
    layouts: ['Atlantisbahamas', 'AtlantisMobileApp', 'NPIWFF', 'Summer2024'],
  },
  slots: [{ name: 'default' }],
  description: 'Empty container with child slices',
  tags: ['Gallery', 'Index'],
  preview: 'SlicesGlueGalleriesStaticGriddedGallery.jpg',
  fields: {
    title: { type: 'text', label: 'Text' },
    copy: { type: 'textarea', label: 'Copy' },
    link: { type: 'link', label: 'Link', enabled: false },
    slot1image: { type: 'media', label: 'Slot 1 Image (top left)', breakpoints: { md: { width: 280, height: 560 }, default: { width: 153, height: 153 } } },
    slot1files: { type: 'files', label: 'Slot 1 Files' },
    slot2image: { type: 'media', label: 'Slot 2 Image (big middle)', breakpoints: { md: { width: 720, height: 840 }, default: { width: 315, height: 221 } } },
    slot2files: { type: 'files', label: 'Slot 2 Files' },
    slot3image: { type: 'media', label: 'Slot 3 Image (top right)', breakpoints: { md: { width: 260, height: 410 }, default: { width: 153, height: 315 } } },
    slot3files: { type: 'files', label: 'Slot 3 Files' },
    slot4image: { type: 'media', label: 'Slot 4 Image (bottom left)', breakpoints: { md: { width: 280, height: 270 }, default: { width: 153, height: 153 } } },
    slot4files: { type: 'files', label: 'Slot 4 Files' },
    slot5image: { type: 'media', label: 'Slot 5 Image (bottom right)', breakpoints: { md: { width: 660, height: 420 }, default: { width: 153, height: 315 } } },
    slot5files: { type: 'files', label: 'Slot 5 Files' },
    slot6image: { type: 'media', label: 'Slot 6 Image (mobile only)', breakpoints: { default: { width: 153, height: 153 } } },
    slot6files: { type: 'files', label: 'Slot 6 Files' },
  },
  templates: [{
    label: 'Static Gridded Gallery',
    fields: {
      title: { value: 'celebration scenes' },
      slot1image: { value: 'https:\/\/atlantis-cms-assets.s3.us-east-2.amazonaws.com\/media\/Special%20Occasions\/atlantis-weddings-social-celebrations-cake.jpg' },
      slot2image: { value: 'https:\/\/atlantis-cms-assets.s3.us-east-2.amazonaws.com\/media\/Special%20Occasions\/alfredandersonweddings-2019-10-13-3350378742.jpg' },
      slot3image: { value: 'https:\/\/atlantis-cms-assets.s3.us-east-2.amazonaws.com\/media\/Special%20Occasions\/celebrations-a4.jpg' },
      slot4image: { value: 'https:\/\/atlantis-cms-assets.s3.us-east-2.amazonaws.com\/media\/Special%20Occasions\/celebrate.PNG' },
      slot5image: { value: 'https:\/\/atlantis-cms-assets.s3.us-east-2.amazonaws.com\/media\/Special%20Occasions\/atlantis-stock-image-heart-in-sand-shutterstock-419665789.jpg' },
    },
  }],
})

const { $voix }: any = useNuxtApp()

const galleryStyles = computed(() => {
  const styles = { height: '840px' }

  if ($voix.$breakpoint === 'default' || $voix.$breakpoint === 'sm')
    styles.height = '1000px'
  else if ($voix.$breakpoint === 'md')
    styles.height = '1700px'
  else if ($voix.$breakpoint === 'lg')
    styles.height = '2000px'

  return styles
})
function findGallery(slotNumber: number) {
  return props.fields[`slot${slotNumber}files`].value
}

const slotOneGallerySlice = computed(() => {
  return findGallery(1)
})

const slotTwoGallerySlice = computed(() => {
  return findGallery(2)
})

const slotThreeGallerySlice = computed(() => {
  return findGallery(3)
})

const slotFourGallerySlice = computed(() => {
  return findGallery(4)
})

const slotFiveGallerySlice = computed(() => {
  return findGallery(5)
})

const slotSixGallerySlice = computed(() => {
  return findGallery(6)
})

const activeGallery = ref(0)
const activeImage = ref(0)
function requestOpenGallery(galleryNumber: number) {
  activeGallery.value = galleryNumber
  activeImage.value = 0
}

function closeGallery() {
  activeGallery.value = 0
}

const gallery = computed(() => {
  const gallery = []

  if (slotOneGallerySlice.value && activeGallery.value === 1)
    gallery.push(...slotOneGallerySlice.value)

  if (slotTwoGallerySlice.value && activeGallery.value === 2)
    gallery.push(...slotTwoGallerySlice.value)

  if (slotThreeGallerySlice.value && activeGallery.value === 3)
    gallery.push(...slotThreeGallerySlice.value)

  if (slotFourGallerySlice.value && activeGallery.value === 4)
    gallery.push(...slotFourGallerySlice.value)

  if (slotFiveGallerySlice.value && activeGallery.value === 5)
    gallery.push(...slotFiveGallerySlice.value)

  if (slotSixGallerySlice.value && activeGallery.value === 6)
    gallery.push(...slotSixGallerySlice.value)

  return gallery
})

function prev() {
  if (activeImage.value > 0)
    activeImage.value--
}

function next() {
  if (activeImage.value < gallery.value.length - 1)
    activeImage.value++
}
</script>

<template>
  <div>
    <div class="grid grid-cols-4 lg:grid-cols-12 gap-2 px-8 lg:px-0" :style="galleryStyles">
      <VoixMedia v-slot="slotProps" :field="fields.slot1image" background>
        <div
          class="cursor-pointer relative bg-cover bg-center bg-pink-500 col-start-1 row-start-1 row-span-4 lg:row-span-8 col-span-2 lg:col-span-2"
          :style="{ backgroundImage: `url('${slotProps.image}')` }"
          @click="requestOpenGallery(1)"
        >
          <div v-if="slotOneGallerySlice" class="absolute inset-0">
            <div
              class="absolute inset-0 transition-opacity duration-300 opacity-0 hover:opacity-100 text-white font-medium text-xl font-sans2 uppercase"
            >
              <div class="opacity-50 bg-glueblue-700 absolute inset-0 z-0" />
            </div>

            <svg
              class="absolute bottom-0 right-0 mb-4 mr-4 w-6 h-6 opacity-80 text-white" fill="none" stroke="currentColor"
              viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
              />
            </svg>
          </div>
        </div>
      </VoixMedia>
      <VoixMedia v-slot="slotProps" :field="fields.slot2image" background>
        <div
          class="relative bg-cover bg-center bg-red-500 row-span-4 lg:row-span-12 col-span-4 lg:col-span-5 row-start-5 col-start-1 lg:row-start-auto lg:col-start-auto"
          :style="{ backgroundImage: `url('${slotProps.image}')` }"
        >
          <div v-if="slotTwoGallerySlice && slotTwoGallerySlice.length" class="absolute inset-0">
            <div
              class="absolute inset-0 transition-opacity duration-300 opacity-0 hover:opacity-100 text-white font-medium text-xl font-sans2 uppercase"
            >
              <div class="opacity-50 bg-glueblue-700 absolute inset-0 z-0" />
              <div class="absolute inset-0 flex justify-center items-center">
                {{ slotTwoGallerySlice?.title?.text }}
              </div>
            </div>

            <svg
              class="absolute bottom-0 right-0 mb-4 mr-4 w-6 h-6 opacity-80 text-white" fill="none" stroke="currentColor"
              viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
              />
            </svg>
          </div>
        </div>
      </VoixMedia>
      <div
        class="content-grid-cell flex flex-col justify-between bg-cover bg-center bg-glueblue-700 hotel-theme-bg row-span-1 lg:row-span-6 col-span-4 lg:col-span-3 text-white lg:text-right p-8"
      >
        <div>
          <div class=" mb-4 text-3xl leading-[1.1] xl:text-4xl font-sans font-light uppercase">
            {{ fields.title.value }}
          </div>
          <div class="body-lg">
            {{ fields.copy.value }}
          </div>
        </div>
        <div v-if="fields.link.enabled">
          <a
            :href="fields.link.value?.href"
            class="btn-ghost btn-active"
          >
            {{ fields.link.value.text }}
          </a>
        </div>
      </div>
      <VoixMedia v-slot="slotProps" :field="fields.slot3image" background>
        <div
          class="relative bg-cover bg-center bg-orange-700 row-span-4 lg:row-span-6 col-span-1 lg:col-span-2"
          :style="{ backgroundImage: `url('${slotProps.image}')` }"
          @click="requestOpenGallery(2)"
        >
          <div v-if="slotThreeGallerySlice && slotThreeGallerySlice.length" class="absolute inset-0">
            <div
              class="absolute inset-0 transition-opacity duration-300 opacity-0 hover:opacity-100 text-white font-medium text-xl font-sans2 uppercase"
            >
              <div class="opacity-50 bg-glueblue-700 absolute inset-0 z-0" />
              <div class="absolute inset-0 flex justify-center items-center">
                {{ slotThreeGallerySlice?.title?.text }}
              </div>
            </div>

            <svg
              class="absolute bottom-0 right-0 mb-4 mr-4 w-6 h-6 opacity-80 text-white" fill="none" stroke="currentColor"
              viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
              />
            </svg>
          </div>
        </div>
      </VoixMedia>
      <VoixMedia v-slot="slotProps" :field="fields.slot5image" background>
        <div
          class="relative bg-cover bg-center bg-indigo-500 row-span-4 lg:row-span-6 col-span-2 lg:col-span-5"
          :style="{ backgroundImage: `url('${slotProps.image}')` }"
          @click="requestOpenGallery(3)"
        >
          <div v-if="slotFiveGallerySlice && slotFiveGallerySlice.length" class="absolute inset-0">
            <div
              class="absolute inset-0 transition-opacity duration-300 opacity-0 hover:opacity-100 text-white font-medium text-xl font-sans2 uppercase"
            >
              <div class="opacity-50 bg-glueblue-700 absolute inset-0 z-0" />
              <div class="absolute inset-0 flex justify-center items-center">
                {{ slotFiveGallerySlice?.title?.text }}
              </div>
            </div>

            <svg
              class="absolute bottom-0 right-0 mb-4 mr-4 w-6 h-6 opacity-80 text-white" fill="none" stroke="currentColor"
              viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
              />
            </svg>
          </div>
        </div>
      </VoixMedia>
      <VoixMedia v-slot="slotProps" :field="fields.slot4image" background>
        <div
          class="relative cursor-pointer pointer-events-auto bg-cover bg-center bg-teal-500 row-span-4 lg:row-span-4 col-span-2 lg:col-span-2 row-start-1 col-start-3 lg:row-start-auto lg:col-start-auto"
          :style="{ backgroundImage: `url('${slotProps.image}')` }"
          @click="requestOpenGallery(4)"
        >
          <div v-if="slotFourGallerySlice && slotFourGallerySlice.length" class="absolute inset-0">
            <div
              class="absolute inset-0 transition-opacity duration-300 opacity-0 hover:opacity-100 text-white font-medium text-xl font-sans2 uppercase"
            >
              <div class="opacity-50 bg-glueblue-700 absolute inset-0 z-0" />
              <div class="absolute inset-0 flex justify-center items-center">
                {{ slotFourGallerySlice?.title?.text }}
              </div>
            </div>

            <svg
              class="absolute bottom-0 right-0 mb-4 mr-4 w-6 h-6 opacity-80 text-white" fill="none" stroke="currentColor"
              viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
              />
            </svg>
          </div>
        </div>
      </VoixMedia>
      <VoixMedia v-slot="slotProps" :field="fields.slot4image" background>
        <div
          class="bg-cover bg-center bg-yellow-500 row-span-4 col-span-1 lg:hidden"
          :style="{ backgroundImage: `url('${slotProps.image}')` }"
          @click="requestOpenGallery(6)"
        >
          <div v-if="slotSixGallerySlice && slotSixGallerySlice.length" class="absolute inset-0">
            <div
              class="absolute inset-0 transition-opacity duration-300 opacity-0 hover:opacity-100 text-white font-medium text-xl font-sans2 uppercase"
            >
              <div class="opacity-50 bg-glueblue-700 absolute inset-0 z-0" />
              <div class="absolute inset-0 flex justify-center items-center">
                {{ slotSixGallerySlice?.title?.text }}
              </div>
            </div>

            <svg
              class="absolute bottom-0 right-0 mb-4 mr-4 w-6 h-6 opacity-80 text-white" fill="none" stroke="currentColor"
              viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
              />
            </svg>
          </div>
        </div>
      </VoixMedia>
    </div>
    <Teleport v-if="activeGallery > 0" to="#portal-modal">
      <GlueUiModal :darkmode="true" modal-classes="w-full mx-4" @close="closeGallery">
        <div class="pointer-events-none">
          <div class="relative flex flex-col justify-between" style="height: 90vh">
            <div class="mb-4 text-white" style="max-height: 65vh">
              <div
                v-for="(image, key) in gallery"
                :key="key"
                class="w-full flex flex-col justify-between"
              >
                <div v-if="activeImage === key">
                  <div
                    class="w-full  h-[60vh] overflow-hidden flex justify-center items-center bg-center bg-contain bg-no-repeat relative"
                  >
                    <template v-if="image.url">
                      <VoixMedia :field="image.url" class="w-full h-full object-contain" />
                    </template>
                    <template v-else>
                      <VoixMedia v-if="$voix.breakpoint.isGreaterThan('sm')" :field="image" breakpoint="lg" class="w-full h-full object-contain" />
                      <VoixMedia v-else :field="image" breakpoint="sm" class="w-full h-full object-contain" />
                    </template>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="absolute z-20 inset-0 flex justify-between items-center px-8 pointer-events-none"
            >
              <div
                class="pointer-events-auto cursor-pointer rounded-full w-10 h-10 flex justify-center items-center transition-all duration-200 hover:bg-glueblue-600"
                style="
                height: 2.5rem;
                width: 2.5rem;
                box-shadow: 0 0 1px 0px white inset, 0 0 1px 1px white;
                margin-top: -1.25rem;
              "
                @click="prev"
              >
                <IconsArrow class="w-5 text-gray-100" />
              </div>
              <div
                class="pointer-events-auto cursor-pointer rounded-full w-10 h-10 flex justify-center items-center transition-all duration-200 hover:bg-glueblue-600"
                style="
                height: 2.5rem;
                width: 2.5rem;
                box-shadow: 0 0 1px 0px white inset, 0 0 1px 1px white;
                margin-top: -1.25rem;
              "
                @click="next"
              >
                <IconsArrow class="w-5 transform rotate-180 text-gray-100" />
              </div>
            </div>

            <div class="container mx-auto select-none">
              <div
                class="hidden md:flex w-full pt-2 pb-4 overflow-x-scroll no-scrollbar max-w-full transform"
                style="max-height: 25vh"
              >
                <div
                  v-for="(image, key) in gallery"
                  :key="key"
                  :ref="`imageThumb${key}`"
                  class="mr-2 cursor-pointer pointer-events-auto flex-shrink-0 duration-200 transform border-2"
                  :class="{
                    'border-white -translate-y-2': activeImage === key,
                    'border-transparent': activeImage !== key,
                  }"
                  @click="activeImage = key"
                >
                  <div class="w-full text-white">
                    <VoixMedia v-if="image.url" :field="image.url" class="h-32" draggable="false" />
                    <VoixMedia v-else :field="image" breakpoint="default" class="h-32" draggable="false" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </GlueUiModal>
    </Teleport>
  </div>
</template>

<style lang="scss" scoped>
html[data-theme="summer-2024-theme"],
.summer-2024-theme {

  .content-grid-cell {
    @apply bg-summer2024-400;

  }

}
</style>
